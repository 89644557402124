<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports"/>
      </v-col>
    </v-row>

    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50
          "
        >

          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04) ">
                <p class="pa-4 font-extrabold text-xl text-blue" >Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row >
            <v-col md="4" class="ml-2">
              <date-period
                  :dense="true"
                  :dateType="dateToggle"
                  @periodChange="dateChange"
                  class-name="q-text-field shadow-0"
              ></date-period>
            </v-col>

          </v-row>


          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col>User Audit Report</v-col>
                  <v-spacer></v-spacer>
                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-if="
                          checkExportPermission($modules.reports.service.slug)"
                            class="export-button mt-2"
                            elevation="0"
                            height="40"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <SvgIcon text="Export Report" >
                            <template v-slot:icon>
                              <ExportIcon/>
                            </template>
                          </SvgIcon>
                        </v-btn>

                      </template>
                      <v-list>

                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>

            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    class="
                      md-content md-table-content md-scrollbar md-theme-default
                    "
                  >
                    <table class="logTable">
                      <thead class="md-card-header shadow-0">
                        <tr>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                              style="text-align:center;"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Action date
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                User
                              </div>
                            </div>
                          </th>

                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">
                                Description
                              </div>
                            </div>
                          </th>

                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Action</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <template v-for="(data, lndex) in userAuditReport">
                          <tr :key="`dayTotal_${lndex}`" class="md-table-row ">
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ data.created_at | timeStamp }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ data.operator_name }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ data.description }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ data.action }}
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <v-pagination
                      class="mt-3 new-pagination"
                      v-model="page"
                      :length="totalPages"
                    ></v-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
export default {
  components: {ExportIcon, SvgIcon, BackButton},
  data() {
    return {
      timeDuration: [
        { name: "All", title: "All" },
        { name: "week", title: "This Week" },
        { name: "year", title: "This Year" },
        { name: "month", title: "This Month" },
        { name: "custom", title: "Custom Duration" },
      ],

      dateToggle: "DATE",
      userAuditReport: [],
      menu1: false,
      menu2: false,

      searchParams: {
        // from_date: moment()
        //   .subtract(30, "days")
        //   .format("YYYY-MM-DD"),
        // to_date: moment().format("YYYY-MM-DD"),
        timestamp: [],
      },
      date1: moment()
        .subtract(15, "days")
        .format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      visitationData: {
        bookings: [],
        scanned_bookings: [],
        scanned_qr_bookings: [],
        quick_scanned_bookings: [],
      },
      visitationDataB2C: {
        facility_bookings: [],
        academy_bookings: [],
      },
      totalPages: 0,
      page: 1,
      paginatedData: [],
      mandatory: false,
      hoursArray: [],
    };
  },
  watch: {
    page: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getReport();
        }
      },
    },
  },
  computed: {
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    dateLength() {
      return this.visitationCountReport.length;
    },
    rowSpan() {
      return parseInt(this.visitationCountReport.length / 4);
    },
  },
  mounted() {
    this.getReport();
    this.setHoursArray();
  },
  methods: {
    toggle(type, field) {
      this.$nextTick(() => {
        if (this.searchParams[field].length == this[type].length) {
          this.searchParams[field] = [];
        } else {
          this.searchParams[field] = this[type].map((item) => item.id);
        }
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (this.searchParams[field].length == this[type].length) {
        icon = "mdi-close-box";
      }
      if (
        this.searchParams[field].length > 0 &&
        this.searchParams[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/user-audit-report${url}&page=${this.page}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data;
            this.userAuditReport = data.data;
            this.totalPages = response.data.total_pages;
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    reportDownload(type) {
      let link = "user-audit-report/download";

      let url = this.getFilterUrlData();
      if (!url) return;
      this.$http
        .get(`venues/reports/` + link + `${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      // let url = `?date_type=${this.dateToggle.toLowerCase()}`;

      // url += `&from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      let url = `?date_type=${this.dateToggle.toLowerCase()}&from_date=${
        this.date1
      }&to_date=${this.date2}`;

      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;

      if (this.fileName != null)
        this.fileName = "User-Audit-Report-" + this.fileName;
      else this.fileName = "User-Audit-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function(opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },

    dateChange(data) {
      //   this.searchParams.from_date = data.date1;
      //  this.searchParams.to_date = data.date2;
      this.date1 = data.date1;
      this.date2 = data.date2;
      this.getReport();
    },
    setHoursArray() {
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 1; j++) {
          this.hoursArray.push(`${i}:${j === 0 ? `00` : 60 * j}`);
        }
      }
      // console.log(this.hoursArray);
    },
  },
};
</script>

<style scoped>
.logTable tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}

.section_head_bg {
  background: #edf9ff;
}

.md-card-header{
  background-color: #edf7f8;
}
</style>
